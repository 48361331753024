@import '../function';


#mdp::placeholder {
    visibility: hidden;
}

#mdpconfirmation::placeholder {
    visibility: hidden;
}
